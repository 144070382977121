import { Modal, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { App, Button, Input, Select, Tag } from "antd";
import { DeleteOutlined, InfoCircleFilled } from "@ant-design/icons";
import CurrentEventContext from "contexts/CurrentEventContext";
import axios from "../../../../services/axios";

const NewParticipantModal = ({ open, close, reload, base_info = null }) => {
	const { event } = useContext(CurrentEventContext);
	const [newParticipant, setNewParticipant] = useState({
		email: "",
		first_name: "",
		last_name: "",
		tickets: [],
	});
	const [loading, setLoading] = useState(false);
	const handleClose = () => {
		setNewParticipant({
			email: "",
			first_name: "",
			last_name: "",
			tickets: [],
		});
		close();
	};
	const { message } = App.useApp();

	useEffect(() => {
		if (base_info) {
			setNewParticipant({
				...newParticipant,
				email: base_info.email,
				first_name: base_info.first_name,
				last_name: base_info.last_name,
			});
		}
	}, [base_info]);

	const save = async () => {
		setLoading(true);
		const ret = await axios
			.post(`/api/payments/manual/${event.id}`, {
				...newParticipant,
				tickets: newParticipant.tickets.map((ticket) => ({
					id: ticket.ticket,
					amount: parseInt(ticket.buy_amount),
					title: "",
					description: "",
					ticket_type: "",
				})),
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			message.success(base_info ? "Ticket ajouté avec succès" : "Participant ajouté avec succès");
			reload();
			handleClose();
		}
		setLoading(false);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
		>
			<div className="center-new-participant-form-modal">
				<div className="modal-header">
					<span>{base_info ? "Nouveau ticket" : "Nouveau participant"}</span>
				</div>
				<div className="modal-content">
					<div className="field-row">
						<div className="field-block">
							<span>Prénom</span>
							<Input
								disabled={base_info ? true : false}
								className="input"
								value={newParticipant.first_name}
								onChange={(e) => setNewParticipant({ ...newParticipant, first_name: e.target.value })}
								placeholder="Prénom du participant"
							/>
						</div>
						<div className="field-block">
							<span>Nom</span>
							<Input
								disabled={base_info ? true : false}
								className="input"
								value={newParticipant.last_name}
								onChange={(e) => setNewParticipant({ ...newParticipant, last_name: e.target.value })}
								placeholder="Nom du participant"
							/>
						</div>
					</div>
					<div className="field-block">
						<span>Email</span>
						<Input
							disabled={base_info ? true : false}
							className="input"
							value={newParticipant.email}
							onChange={(e) => setNewParticipant({ ...newParticipant, email: e.target.value })}
							placeholder="Email du participant"
						/>
					</div>
					<div className="ticket-list">
						<span>Tickets</span>
						{newParticipant.tickets.map((ticket, index) => (
							<div key={index} className="ticket-item">
								<div className="flex flex-row items-center gap-[10px]">
									<span>#{index + 1}</span>
									<Select
										style={{ width: "100%" }}
										placeholder="Select ticket"
										value={ticket.id}
										onChange={(e) =>
											setNewParticipant({
												...newParticipant,
												tickets: newParticipant.tickets.map((t, i) =>
													i === index
														? {
																...t,
																ticket: e,
																amount: event.tickets.find((ticket) => ticket.id === e)
																	.amount,
																purchased: event.tickets.find(
																	(ticket) => ticket.id === e
																).purchased,
														  }
														: t
												),
											})
										}
										options={event.tickets.map((ticket) => ({
											value: ticket.id,
											label: ticket.title,
										}))}
										className="select-item"
										showSearch
										optionFilterProp="label"
									/>
								</div>
								<div className="flex flex-row items-center gap-[10px]">
									<Tag color="">
										{ticket.purchased} / {ticket.amount} tickets vendus
									</Tag>
									<Input
										type="number"
										value={ticket.buy_amount}
										onChange={(e) =>
											setNewParticipant({
												...newParticipant,
												tickets: newParticipant.tickets.map((t, i) =>
													i === index ? { ...t, buy_amount: e.target.value } : t
												),
											})
										}
										placeholder="Nombre de tickets"
									/>
									<div>
										<Button
											onClick={() => {
												setNewParticipant({
													...newParticipant,
													tickets: newParticipant.tickets.filter((t, i) => i !== index),
												});
											}}
											icon={<DeleteOutlined style={{ color: "red" }} />}
										/>
									</div>
								</div>
							</div>
						))}
						<Button
							type="default"
							onClick={() =>
								setNewParticipant({
									...newParticipant,
									tickets: [
										...newParticipant.tickets,
										{ ticket: null, amount: 0, purchased: 0, buy_amount: null },
									],
								})
							}
						>
							Nouveau ticket
						</Button>
					</div>
				</div>
				<div className="modal-footer">
					<Button onClick={handleClose}>Annuler</Button>
					<Button
						onClick={save}
						disabled={
							newParticipant.first_name === "" ||
							newParticipant.last_name === "" ||
							newParticipant.email === "" ||
							newParticipant.tickets.length === 0 ||
							newParticipant.tickets.some(
								(t) => t.buy_amount === null || t.buy_amount === "" || t.ticket === null
							)
						}
						className="main"
						type="primary"
						loading={loading}
					>
						{"Créer"}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default NewParticipantModal;
