import React, { useEffect, useState } from "react";
import TableHeader from "../table_header/TableHeader";
import TableContent from "../table_content/TableContent";
import TableFooter from "../table_footer/TableFooter";

const PassPassTable = ({
	title = "",
	data = [],
	columns = [],
	pageSizeOptions = [],
	light = false,
	search = null,
	options = [],
}) => {
	const [reworkData, setReworkData] = useState({
		data: [],
		shown_data: [],
		rows_per_page: pageSizeOptions.length ? pageSizeOptions[0] : 10,
		current_page: 0,
		max_page: 0,
	});
	const [selection, setSelection] = useState([]);
	const [reworkColumns, setReworkColumns] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		const new_data = [...data];
		new_data.forEach((line, index) => (line.passpass_table_id = index));

		const search_list = searchValue.toLowerCase().split(" ");

		const filtered_data = new_data.filter((item) => {
			if (searchValue === "" || search === null) return true;
			let search_str = "";
			for (const field in search.fields) {
				search_str += item[search.fields[field]].toLowerCase() + "|";
			}
			for (let index = 0; index < search_list.length; index++) {
				const item = search_list[index];
				if (search_str.includes(item)) {
					return true;
				}
			}
			return false;
		});

		const start_index = reworkData.current_page * reworkData.rows_per_page;
		const end_index = reworkData.current_page * reworkData.rows_per_page + reworkData.rows_per_page;

		const max_page =
			Math.floor(filtered_data.length / reworkData.rows_per_page) ==
			filtered_data.length / reworkData.rows_per_page
				? Math.floor(filtered_data.length / reworkData.rows_per_page)
				: Math.floor(filtered_data.length / reworkData.rows_per_page) + 1;

		setReworkData({
			data: filtered_data,
			shown_data: filtered_data.slice(start_index, end_index),
			rows_per_page: pageSizeOptions.length ? pageSizeOptions[0] : 10,
			current_page: 0,
			max_page: max_page,
		});
	}, [data, searchValue]);

	useEffect(() => {
		setReworkColumns(columns.filter((column) => column.hidden !== true));
	}, [columns]);

	const select_item = (item_id, count = 0, total = 0) => {
		const new_selection = [...selection];
		if (item_id === "all") {
			if (count === total) {
				for (let index = 0; index < reworkData.shown_data.length; index++) {
					const item = reworkData.shown_data[index];
					if (new_selection.includes(item.passpass_table_id)) {
						new_selection.splice(new_selection.indexOf(item.passpass_table_id), 1);
					}
				}
			} else {
				for (let index = 0; index < reworkData.shown_data.length; index++) {
					const item = reworkData.shown_data[index];
					if (!new_selection.includes(item.passpass_table_id)) {
						new_selection.push(item.passpass_table_id);
					}
				}
			}
		} else {
			if (new_selection.includes(item_id)) {
				new_selection.splice(new_selection.indexOf(item_id), 1);
			} else {
				new_selection.push(item_id);
			}
		}

		setSelection(new_selection);
	};

	const set_row_per_page = (count) => {
		set_page(reworkData.current_page, count);
	};

	const set_page = (page, rows_per_page = reworkData.rows_per_page) => {
		if (page < 0 || page > reworkData.max_page - 1) return;
		const new_data = [...data];
		page = page === "last" ? reworkData.max_page - 1 : page === "first" ? 0 : page;

		const max_page =
			Math.floor(data.length / rows_per_page) === data.length / rows_per_page
				? Math.floor(data.length / rows_per_page)
				: Math.floor(data.length / rows_per_page) + 1;

		page = page >= max_page ? max_page - 1 : page;

		const start_index = page * rows_per_page;
		const end_index = page * rows_per_page + rows_per_page;

		setReworkData({
			...reworkData,
			shown_data: new_data.slice(start_index, end_index),
			rows_per_page: rows_per_page,
			max_page: max_page,
			current_page: page,
		});
	};

	return (
		<div className="passpass-table-container">
			{!light && (
				<TableHeader
					title={title}
					columns={reworkColumns}
					all_columns={columns}
					full_data={data}
					base_columns={columns}
					selection={selection}
					clearSelection={() => setSelection([])}
					setColumns={setReworkColumns}
					search={search}
					onSearch={setSearchValue}
					options={options}
				/>
			)}
			<TableContent
				data={reworkData.shown_data}
				columns={[...reworkColumns].sort((a, b) => a.order - b.order)}
				selection={selection}
				select={select_item}
			/>
			<TableFooter
				set_page={set_page}
				set_row_per_page={set_row_per_page}
				selection_size={selection.length}
				full_data={reworkData}
				page_size_options={pageSizeOptions}
			/>
		</div>
	);
};

export default PassPassTable;
